/*
read: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
use: https://jakearchibald.github.io/svgomg/
*/

import React from 'react';

const icons = {
  arrowsLeft:
    '<defs><linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#a)" d="M11.23068 10L15 13.88889 13.92305 15l-4.84627-5 4.84627-5L15 6.11111 11.23068 10zM6.1539 10l3.76932 3.88889L8.84627 15 4 10l4.84627-5 1.07695 1.11111L6.1539 10z"/>',

  arrowsRight:
    '<defs><linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#b)" d="M8.76932 10L5 6.11111 6.07695 5l4.84627 5-4.84627 5L5 13.88889 8.76932 10zm5.07678 0l-3.76932-3.88889L11.15373 5 16 10l-4.84627 5-1.07695-1.11111L13.8461 10z"/>',

  back:
    '<defs><linearGradient id="c" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#c)" d="M12.5 13.88889L11.4091 15 6.5 10l4.9091-5L12.5 6.11111 8.68182 10z"/>',

  cart:
    '<defs><linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#d)" fill-rule="evenodd" d="M5.127 2H2v1.1531h2.21173L5.88043 14h9.87367v-1.1531H6.79748l-.17981-1.2223h9.62553L18 4.02562H5.43987L5.127 2zM14.5 15c-.8283 0-1.5.67155-1.5 1.5s.6717 1.5 1.5 1.5c.82845 0 1.5-.67155 1.5-1.5s-.67155-1.5-1.5-1.5zm-7 0c-.82845 0-1.5.67155-1.5 1.5S6.67155 18 7.5 18 9 17.32845 9 16.5 8.32845 15 7.5 15z"/>',

  cartLight:
    '<defs><linearGradient id="g" x1="50%" x2="50%" y1="23.73352%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#g)" fill-rule="evenodd" d="M5.127 2H2v1.1531h2.21173L5.88043 14h9.87367v-1.1531H6.79748l-.17981-1.2223h9.62553L18 4.02562H5.43987L5.127 2zM14.5 15c-.8283 0-1.5.67155-1.5 1.5s.6717 1.5 1.5 1.5c.82845 0 1.5-.67155 1.5-1.5s-.67155-1.5-1.5-1.5zm-7 0c-.82845 0-1.5.67155-1.5 1.5S6.67155 18 7.5 18 9 17.32845 9 16.5 8.32845 15 7.5 15z"/></svg>',

  slideshow:
    '<defs><linearGradient id="e" x1="50%" x2="50%" y1="44.10777%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><rect width="10" height="14" x="5" y="3" fill="url(#e)" fill-rule="evenodd" rx="1"/>',

  thumbnails:
    '<defs><linearGradient id="f" x1="50%" x2="50%" y1="44.10777%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><g fill="url(#f)" fill-rule="evenodd"><rect width="4" height="4" x="2" y="2" rx="1"/><rect width="4" height="4" x="2" y="8" rx="1"/><rect width="4" height="4" x="2" y="14" rx="1"/><rect width="4" height="4" x="8" y="2" rx="1"/><rect width="4" height="4" x="8" y="8" rx="1"/><rect width="4" height="4" x="8" y="14" rx="1"/><rect width="4" height="4" x="14" y="2" rx="1"/><rect width="4" height="4" x="14" y="8" rx="1"/><rect width="4" height="4" x="14" y="14" rx="1"/></g>',

  close:
    '<defs><linearGradient id="g" x1="69.916%" x2="69.916%" y1="32.122%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#g)" fill-rule="evenodd" d="M10 8.636L13.636 5 15 6.364 11.364 10 15 13.636 13.636 15 10 11.364 6.364 15 5 13.636 8.636 10 5 6.364 6.364 5z"/>',

  plus:
    '<defs><linearGradient id="h" x1="50%" x2="50%" y1="0%" y2="98.686%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#h)" fill-rule="nonzero" d="M11 11v4H9v-4H5V9h4V5h2v4h4v2h-4z"/>',

  minus:
    '<defs><linearGradient id="i" x1="50%" x2="50%" y1="0%" y2="98.686%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#i)" fill-rule="nonzero" d="M15 9v2H5V9z"/>',

  info:
    '<defs><linearGradient id="j" x1="50%" x2="50%" y1="98.686%" y2="27.086%"><stop offset="0%" stop-color="#9B9B9B"/><stop offset="100%" stop-color="#414141"/></linearGradient><linearGradient id="k" x1="50%" x2="50%" y1="100%" y2="50%"><stop offset="0%" stop-color="#FFF"/><stop offset="100%" stop-color="#E6E6E6"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="url(#j)"/><path fill="url(#k)" d="M9 8.392h2v7.028H9V8.392zM10 7a1.25 1.25 0 1 1 0-2.5A1.25 1.25 0 0 1 10 7z"/></g>',

  check:
    '<defs><linearGradient id="l" x1="50%" x2="50%" y1="27.086%" y2="98.686%"><stop offset="0%" stop-color="#9B9B9B"/><stop offset="100%" stop-color="#414141"/></linearGradient></defs><path fill="url(#l)" fill-rule="evenodd" d="M9.192 11.48l4.362-5.2 1.04.873-5.234 6.238-3.64-3.053.873-1.04 2.6 2.181zM10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18z"/>',

  infoInverted:
    '<defs><linearGradient id="m" x1="50%" x2="50%" y1="23.734%" y2="98.686%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#m)" fill-rule="evenodd" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9 8.392v7.028h2V8.392H9zM10 7a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 10 7z"/>',

  arrowDown:
    '<defs><linearGradient id="n" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#n)" fill-rule="nonzero" transform="rotate(-90 10 10)" d="M13 13.89L11.9 15 7 10l4.9-5L13 6.11 9.18 10z"/>',

  arrowUp:
    '<defs><linearGradient id="o" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#o)" fill-rule="nonzero" transform="rotate(90 10 10)" d="M13 13.89L11.9 15 7 10l4.9-5L13 6.11 9.18 10z"/>',
};

export default ({ type, ...rest }) => (
  <svg
    viewBox="0 0 20 20"
    {...rest}
    dangerouslySetInnerHTML={{ __html: icons[type] }} // eslint-disable-line react/no-danger
  />
);
