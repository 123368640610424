// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-cart-js": () => import("./../src/templates/Cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-navigation-js": () => import("./../src/templates/Navigation.js" /* webpackChunkName: "component---src-templates-navigation-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-category-js": () => import("./../src/templates/ProductCategory.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

