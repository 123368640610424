import React from 'react';
import { graphql, compose } from 'react-apollo';

import {
  CREATE_NEW_CHECKOUT,
  GET_CHECKOUT,
  REMOVE_LINE_ITEMS,
} from '../data/checkout';

const isBrowser = typeof window !== 'undefined';

const defaultContext = {
  checkoutId: null,
};

const Context = React.createContext(defaultContext);

// Provider with render props
class CartProvider extends React.Component {
  setCheckout = checkoutId => {
    if (isBrowser) {
      localStorage.setItem('shopify_checkout_id', checkoutId);
    }

    this.setState({
      checkoutId,
    });
  };

  initializeCheckout = async () => {
    const { createNewCheckout, existingCheckout, removeLineItems } = this.props;

    if (
      typeof existingCheckout !== 'undefined' &&
      existingCheckout.node !== null
    ) {
      // Make sure this cart hasn’t already been purchased
      if (!existingCheckout.node.completedAt) {
        // Remove all products that are not available anymore from cart (variant = null)
        const unavailableLineItems = existingCheckout.node.lineItems.edges.filter(
          ({ node }) => node.variant === null
        );

        if (unavailableLineItems.length) {
          const unavailableLineItemIds = unavailableLineItems.map(
            ({ node }) => node.id
          );

          await removeLineItems({
            variables: {
              checkoutId: existingCheckout.node.id,
              lineItemIds: unavailableLineItemIds,
            },
          });
        }

        this.setCheckout(existingCheckout.node.id);
        return;
      }
    }

    const { data } = await createNewCheckout();
    this.setCheckout(data.checkoutCreate.checkout.id);
  };

  state = defaultContext;

  componentDidMount() {
    const { existingCheckout } = this.props;

    // Make sure we have a Shopify checkout created for cart management
    if (typeof existingCheckout === 'undefined' || !existingCheckout.loading) {
      this.initializeCheckout();
    }
  }

  componentDidUpdate() {
    const { existingCheckout } = this.props;
    const { checkoutId } = this.state;

    // Make sure we have a Shopify checkout created for cart management
    if (
      checkoutId === null &&
      typeof existingCheckout !== 'undefined' &&
      !existingCheckout.loading
    ) {
      this.initializeCheckout();
    }
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

// Check for an existing cart
const existingCheckoutId = isBrowser
  ? localStorage.getItem('shopify_checkout_id')
  : null;

const Provider = compose(
  graphql(REMOVE_LINE_ITEMS, { name: 'removeLineItems' }),
  graphql(CREATE_NEW_CHECKOUT, { name: 'createNewCheckout' }),
  graphql(GET_CHECKOUT, {
    name: 'existingCheckout',
    skip: existingCheckoutId === null,
    options: {
      variables: {
        checkoutId: existingCheckoutId,
      },
    },
  })
)(CartProvider);

const { Consumer } = Context;

export default { Consumer, Provider };
